import CONSTANTS from '@/constants'
import api from './api'

// Outlet apis
const outlets = {
	datatable(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/outlets/datatable`, params)
	},
	store(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/outlets`, params)
	},
	get(id) {
		return api.get(`${CONSTANTS.API_BASE_URL}/api/outlets/${id}`)
	},
	update(id, formData) {
		formData.append('_method', 'PUT')

		return api.post(`${CONSTANTS.API_BASE_URL}/api/outlets/${id}`, formData)
	},
	destroy(id) {
		return api.delete(`${CONSTANTS.API_BASE_URL}/api/outlets/${id}`)
	},
	all() {
		return api.get(`${CONSTANTS.API_BASE_URL}/api/outlets`)
	},
}

export default outlets
