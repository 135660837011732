import CONSTANTS from '@/constants'
import api from './api'

// feedbck apis
const feedback = {
	datatable(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/feedback/datatable`, params)
	},
	categories() {
		return api.get(`${CONSTANTS.API_BASE_URL}/api/feedback/category`)
	},
	exportdata(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/feedback/export`, params)
	},
}

export default feedback
