<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="500px"
    :retain-focus="false"
  >
    <v-card>
      <v-card-text>
        <slot>
          Are you confirm?
        </slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="error"
          outlined
          @click="isVisible = false"
        >
          {{ cancelLabel }}
        </v-btn>
        <v-btn
          small
          color="primary"
          @click="confirmAction"
        >
          {{ confirmLabel }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
	props: {
		value: Boolean,
		cancelLabel: {
			type: String,
			default: 'Cancel',
		},
		confirmLabel: {
			type: String,
			default: 'Confirm',
		},
	},
	data() {
		return {}
	},
	computed: {
		isVisible: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
	methods: {
		confirmAction() {
			this.isVisible = false
			this.$emit('onConfirm')
		},
	},
}
</script>
