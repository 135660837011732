import CONSTANTS from '@/constants'
import api from './api'

// notification apis
const notification = {
	datatable(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/notification/datatable`, params)
	},
	store(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/notification/save`, params)
	},
}
export default notification
