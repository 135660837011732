import auth from './auth'
import banners from './banners'
import cards from './cards'
import countries from './countries'
import outlets from './outlets'
import promotions from './promotions'
import feedback from './feedback'
import notification from './notification'

export default {
	auth,
	banners,
	cards,
	countries,
	outlets,
	promotions,
	feedback,
	notification,

}
