<template>
  <v-snackbar
    v-model="isVisible"
    :light="$vuetify.theme.light"
  >
    {{ message }}

    <template #action="{ attrs }">
      <v-btn
        :color="type"
        text
        v-bind="attrs"
        @click="isVisible = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
	props: {
		value: Boolean,
		type: {
			type: String,
			default: 'info',
		},
		message: {
			type: String,
			default: '',
		},
	},
	data() {
		return {}
	},
	computed: {
		isVisible: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
}
</script>
