import { make } from 'vuex-pathify'
import repository from '../../apis/repository'

const state = { countries: [] }

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	async all({ commit }) {
		const { data } = await repository.countries.all()
		commit('SET_COUNTRIES', data.countries)

		return data.countries
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
