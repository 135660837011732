import { mdiCheckboxBlankCircleOutline } from '@mdi/js'
import CONSTANTS from './src/constants'

const themeConfig = {

	app: {
		name: CONSTANTS.NAME,
		logo: require('@/assets/images/svg/logo.svg'),
		isDark: false,
		isRtl: false,
		contentLayoutNav: 'vertical', // vertical, horizontal
		routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
		// ! `semi-dark` isn't available for horizontal nav menu
		skinVariant: 'bordered', // default, bordered, semi-dark
		contentWidth: 'semi-dark',
	},
	menu: {
		isMenuHidden: false,
		isVerticalNavMini: false,
		verticalMenuAccordion: true,
		groupChildIcon: mdiCheckboxBlankCircleOutline,
		horizontalNavMenuGroupOpenOnHover: true,
	},
	appBar: {
		/*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
		type: 'fixed', // fixed, static, hidden
		isBlurred: true,
	},
	footer: { type: 'static' }, // fixed, static, hidden
	themes: {
		light: {
			primary: '#396EB0',
			accent: '#5AA469',
			secondary: '#FC997C',
			success: '#56CA00',
			info: '#16B1FF',
			warning: '#FFB400',
			error: '#FF4C51',
		},
		dark: {
			primary: '#396EB0',
			accent: '#5AA469',
			secondary: '#FC997C',
			success: '#56CA00',
			info: '#16B1FF',
			warning: '#FFB400',
			error: '#FF4C51',
		},
	},
}

export default themeConfig
