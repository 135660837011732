import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import auth from './modules/auth'
import banner from './modules/banner'
import card from './modules/card'
import country from './modules/country'
import outlet from './modules/outlet'
import promotion from './modules/promotion'
import feedback from './modules/feedback'
import notification from './modules/notification'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {},
	getters: {},
	mutations: {},
	actions: {},
	modules: {
		appConfig: appConfigStoreModule,
		app,
		auth,
		banner,
		country,
		outlet,
		promotion,
		card,
		feedback,
		notification,

	},
})
