import { mdiBullhornOutline, mdiCardAccountDetailsOutline, mdiFolderOutline, mdiHomeOutline, mdiPresentation, mdiCodeNotEqual } from '@mdi/js'

export default [
	{
		title: 'Home',
		icon: mdiHomeOutline,
		to: 'home',
	},
	{
		title: 'Outlets',
		icon: mdiFolderOutline,
		to: 'outlets.index',
	},
	{
		title: 'Banners',
		icon: mdiPresentation,
		to: 'banners.index',
	},
	{
		title: 'Promotions',
		icon: mdiBullhornOutline,
		to: 'promotions.index',
	},
	{
		title: 'Cards',
		icon: mdiCardAccountDetailsOutline,
		to: 'cards.index',
	},
	{
		title: 'Feedback',
		icon: mdiCodeNotEqual,
		to: 'feedback.index',
	},
	{
		title: 'Notifications',
		icon: mdiCodeNotEqual,
		to: 'notification.index',
	},

]
