const feedback = [
	{
		path: 'feedback',
		name: 'feedback.index',
		component: () => import('@/views/feedback/List.vue'),
		meta: { layout: 'content' },
	},
]

export default feedback
