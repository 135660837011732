const notification = [
	{
		path: 'notifications',
		name: 'notification.index',
		component: () => import('@/views/notification/List.vue'),
		meta: { layout: 'content' },
	},
	{
		path: 'notification/create',
		name: 'notification.create',
		component: () => import('@/views/notification/Create.vue'),
		meta: { layout: 'content' },
	},
]

export default notification
