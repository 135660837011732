import middleware from './middleware'

const auth = [
	{
		path: '/login',
		name: 'auth.login',
		beforeEnter: middleware.guest,
		component: () => import('@/views/auth/Login.vue'),
		meta: { layout: 'blank' },
	},
	{
		path: '/forgot-password',
		name: 'auth.forgot-password',
		beforeEnter: middleware.guest,
		component: () => import('@/views/auth/ForgotPassword.vue'),
		meta: { layout: 'blank' },
	},
	{
		path: '/reset-password',
		name: 'auth.reset-password',
		beforeEnter: middleware.guest,
		component: () => import('@/views/auth/ResetPassword.vue'),
		meta: { layout: 'blank' },
	},
	{
		path: '/settings',
		name: 'auth.settings.index',
		beforeEnter: middleware.user,
		component: () => import('@/views/settings/View.vue'),
		meta: { layout: 'content' },
	},
]

export default auth
