import Vue from 'vue'
import VueRouter from 'vue-router'
import VueRouterMultiguard from 'vue-router-multiguard'
import store from '../store'
import auth from './auth'
import banners from './banners'
import cards from './cards'
import middleware from './middleware'
import outlets from './outlets'
import promotions from './promotions'
import feedback from './feedback'
import notification from './notification'

Vue.use(VueRouter)

const routes = [
	...auth,
	{
		path: '',
		beforeEnter: VueRouterMultiguard([middleware.user]),
		redirect: { name: 'home' },
	},
	{
		path: '/',
		name: 'base',
		component: () => import('@/views/Base.vue'),
		beforeEnter: VueRouterMultiguard([middleware.user]),
		children: [
			{
				path: '/home',
				name: 'home',
				component: () => import('@/views/Home.vue'),
				meta: { layout: 'content' },
			},
			...banners,
			...outlets,
			...promotions,
			...cards,
			...feedback,
			...notification,

		],
	},
	{
		path: '*',
		name: 'error-404',
		component: () => import('@/views/Error404.vue'),
		meta: { layout: 'blank' },
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
})

router.beforeEach((to, _, next) => {
	store.commit('app/SET_ROUTE', to.name)

	return next()
})

export default router
