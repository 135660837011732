import CONSTANTS from '@/constants'
import api from './api'

// Card apis
const cards = {
	datatable(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/cards/datatable`, params)
	},
	get(cardNumber) {
		return api.get(`${CONSTANTS.API_BASE_URL}/api/cards/${cardNumber}`)
	},
	exportdata(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/cards/export`, params)
	},
	deleteCard(cardNumber) {
		return api.delete(`${CONSTANTS.API_BASE_URL}/api/cards/${cardNumber}`)
	},
}

export default cards
