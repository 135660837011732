const promotions = [
	{
		path: 'promotions',
		name: 'promotions.index',
		component: () => import('@/views/promotions/List.vue'),
		meta: { layout: 'content' },
	},
	{
		path: 'promotions/create',
		name: 'promotions.create',
		component: () => import('@/views/promotions/Create.vue'),
		meta: { layout: 'content' },
	},
]

export default promotions
