import { make } from 'vuex-pathify'
import repository from '../../apis/repository'

const state = {
	totalItems: 0,
	items: [],
	options: {},
	search: '',
	item: {},
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	async datatable({ commit }) {
		const { options, search } = state
		const { data } = await repository.promotions.datatable({ ...options, search })
		commit('SET_ITEMS', data.data)
		commit('SET_TOTAL_ITEMS', data.total)

		return data.data
	},
	store(context, payload) {
		return repository.promotions.store(payload)
	},
	update(context, formData) {
		return repository.promotions.update(formData.get('id'), formData)
	},
	destroy(context, id) {
		return repository.promotions.destroy(id)
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
