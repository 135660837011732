import CONSTANTS from '@/constants'
import api from './api'

// Country apis
const countries = {
	all() {
		return api.get(`${CONSTANTS.API_BASE_URL}/api/countries`)
	},
}

export default countries
