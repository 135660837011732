import CONSTANTS from '@/constants'
import api from './api'

// Auth apis
const auth = {
	login(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/login`, params)
	},
	async getProfile() {
		return api.get(`${CONSTANTS.API_BASE_URL}/api/profile`)
	},
	logout() {
		return api.delete(`${CONSTANTS.API_BASE_URL}/api/logout`)
	},
	verifyMail(userId, hash, expires, signature) {
		return api.get(`${CONSTANTS.API_BASE_URL}/api/verify/mail/${userId}/${hash}?expires=${expires}&signature=${signature}`)
	},
	resendVerificationMail() {
		return api.get(`${CONSTANTS.API_BASE_URL}/api/resend/verification-mail`)
	},
	forgotPassword(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/forgot-password`, params)
	},
	resetPassword(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/reset-password`, params)
	},
	changePassword(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/change-password`, params)
	},
	updateProfile(params) {
		return api.put(`${CONSTANTS.API_BASE_URL}/api/profile`, params)
	},
}

export default auth
