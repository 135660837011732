const banners = [
	{
		path: 'banners',
		name: 'banners.index',
		component: () => import('@/views/banners/List.vue'),
		meta: { layout: 'content' },
	},
	{
		path: 'banner/create',
		name: 'banner.create',
		component: () => import('@/views/banners/Create.vue'),
		meta: { layout: 'content' },
	},
]

export default banners
