const outlets = [
	{
		path: 'outlets',
		name: 'outlets.index',
		component: () => import('@/views/outlets/List.vue'),
		meta: { layout: 'content' },
	},
]

export default outlets
