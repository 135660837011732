import CONSTANTS from '@/constants'
import api from './api'

// Banner apis
const banners = {
	datatable(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/banners/datatable`, params)
	},
	store(params) {
		return api.post(`${CONSTANTS.API_BASE_URL}/api/banners`, params)
	},
	get(id) {
		return api.get(`${CONSTANTS.API_BASE_URL}/api/banners/${id}`)
	},
	update(id, formData) {
		formData.append('_method', 'PUT')

		return api.post(`${CONSTANTS.API_BASE_URL}/api/banners/${id}`, formData)
	},
	destroy(id) {
		return api.delete(`${CONSTANTS.API_BASE_URL}/api/banners/${id}`)
	},
}

export default banners
