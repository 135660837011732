import { make } from 'vuex-pathify'
import repository from '../../apis/repository'

const state = {
	totalItems: 0,
	items: [],
	options: {},
	category: 0,
	search: '',
	item: {},
	categories: [],
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	async datatable({ commit }) {
		const { options, search, category } = state
		const { data } = await repository.feedback.datatable({ ...options, search, category })
		commit('SET_ITEMS', data.data)
		commit('SET_TOTAL_ITEMS', data.total)

		return data.data
	},
	async feedback_category({ commit }) {
		const { data } = await repository.feedback.categories()
		console.log(data)
		commit('SET_CATEGORIES', data.feedback_categories)
	},
	async export_data() {
		const {
			// eslint-disable-next-line camelcase
			options, search, created_from, created_to,
		} = state
		const { data } = await repository.feedback.exportdata({
			...options, search, created_from, created_to,
		})

		// commit('SET_ITEM', data.card)
		let csvContent = 'data:text/csv;charset=utf-8'
		csvContent += [
			['', 'Name', 'Email', 'Mobile Number', 'Category', 'Message', 'Created Date'],
			...data.map(item => [item.name, item.email, `'${item.phone}`, item.feedback_category.name, item.message.replace(/[\n\r,]+/g, ' '), item.created_at]),
		]
			.join('\n')
			.replace(/(^\[)|(\]$)/gm, '')

		const dtat = encodeURI(csvContent)
		const link = document.createElement('a')
		link.setAttribute('href', dtat)
		link.setAttribute('download', 'export.csv')
		link.click()

		return data.feedback
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
