const cards = [
	{
		path: 'cards',
		name: 'cards.index',
		component: () => import('@/views/cards/List.vue'),
		meta: { layout: 'content' },
	},
]

export default cards
