import { make } from 'vuex-pathify'
import repository from '../../apis/repository'

const state = {
	totalItems: 0,
	items: [],
	options: {},
	search: '',
	created_from: '',
	created_to: '',
	item: {},
}

const getters = make.getters(state)

const mutations = make.mutations(state)

const actions = {
	async datatable({ commit }) {
		const {
			// eslint-disable-next-line camelcase
			options, search, created_from, created_to,
		} = state
		const { data } = await repository.cards.datatable({
			...options, search, created_from, created_to,
		})
		commit('SET_ITEMS', data.data)
		commit('SET_TOTAL_ITEMS', data.total)

		return data.data
	},
	async get({ commit }, cardNumber) {
		const { data } = await repository.cards.get(cardNumber)
		commit('SET_ITEM', data.card)

		return data.card
	},
	async exportData() {
		const {
			// eslint-disable-next-line camelcase
			options, search, created_from, created_to,
		} = state
		const { data } = await repository.cards.exportdata({
			...options, search, created_from, created_to,
		})

		// commit('SET_ITEM', data.card)
		let csvContent = 'data:text/csv;charset=utf-8'
		csvContent += [
			['', 'Card Number', 'Member Name', 'Member Mobile Number', 'Point Balance', 'Last Sync', 'Created Date'],
			...data.map(item => [item.card_number, item.user?.name, `'${item.user?.mobile_number}`, item.point_balance, item.last_sync, item.created_at]),
		]
			.join('\n')
			.replace(/(^\[)|(\]$)/gm, '')

		const dtat = encodeURI(csvContent)
		const link = document.createElement('a')
		link.setAttribute('href', dtat)
		link.setAttribute('download', 'export.csv')
		link.click()

		return data.card
	},
	async destroy({ commit }, cardNumber) {
		commit('SET_ITEM', cardNumber)
		const { data } = await repository.cards.deleteCard(cardNumber)

		return data
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
