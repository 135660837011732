import { make } from 'vuex-pathify'

const state = {
	shallContentShowOverlay: false,
	snackbar: {
		isVisible: false,
		message: '',
		type: 'info',
	},
	route: null,
}

const getters = make.getters(state)

const defaultMutations = make.mutations(state)

const mutations = {
	...defaultMutations,
	TOGGLE_CONTENT_OVERLAY(value) {
		state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
	},
	SET_SNACKBAR_INFO(stateObject, message) {
		state.snackbar.message = message
		state.snackbar.type = 'info'
		state.snackbar.isVisible = true
	},
	SET_SNACKBAR_SUCCESS(stateObject, message) {
		state.snackbar.message = message
		state.snackbar.type = 'success'
		state.snackbar.isVisible = true
	},
	SET_SNACKBAR_ERROR(stateObject, message) {
		state.snackbar.message = message
		state.snackbar.type = 'error'
		state.snackbar.isVisible = true
	},
	SET_SNACKBAR_WARNING(stateObject, message) {
		state.snackbar.message = message
		state.snackbar.type = 'warning'
		state.snackbar.isVisible = true
	},
	SET_SNACKBAR_EMPTY() {
		state.snackbar.message = ''
		state.snackbar.type = 'info'
		state.snackbar.isVisible = false
	},
}

const actions = {}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
