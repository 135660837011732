import axios from 'axios'

const instance = axios.create()

instance.interceptors.request.use(req => {
	const request = { ...req }
	const { token } = localStorage
	request.headers.common.Accept = 'application/json'
	request.headers.common['Content-Type'] = 'application/json'
	request.headers.common['Access-Control-Allow-Origin'] = '*'
	request.headers.common.Authorization = `Bearer ${token}`

	return request
})

instance.interceptors.response.use(
	response => response,
	error => {
		if (error.response.status === 401 && error.request.responseURL.search('login') === -1) {
			localStorage.removeItem('user')
			localStorage.removeItem('token')
			window.location.reload()
		}

		return Promise.reject(error)
	},

)

export default instance
