<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ user.name }}
          </span>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Settings -->
      <v-list-item :to="{name: 'auth.settings.index'}">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item
        href="#"
        @click="logout"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
	mdiAccountEdit,
	mdiEmailOutline,
	mdiCheckboxMarkedOutline,
	mdiChatOutline,
	mdiCogOutline,
	mdiCurrencyUsd,
	mdiHelpCircleOutline,
	mdiLogoutVariant,
} from '@mdi/js'

import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			icons: {
				mdiAccountEdit,
				mdiEmailOutline,
				mdiCheckboxMarkedOutline,
				mdiChatOutline,
				mdiCogOutline,
				mdiCurrencyUsd,
				mdiHelpCircleOutline,
				mdiLogoutVariant,
			},
		}
	},
	computed: { ...mapGetters('auth', ['user']) },
	methods: {
		async logout() {
			this.error = null
			try {
				await this.$store.dispatch('auth/logout')
			} catch (error) {
				this.error = error
			} finally {
				this.loading = false
				await this.$router.push({ name: 'auth.login' })
			}
		},
	},
}
</script>

<style lang="scss">
.user-profile-menu-content {
	.v-list-item {
		min-height: 2.5rem !important;
	}
}
</style>
