const themeVariant = {
	vuexy: {
		light: {
			primary: '#7367F0',
			accent: '#0d6efd',
			secondary: '#5c636a',
			success: '#62B726',
			info: '#32B8F2',
			warning: '#FFBF3F',
			error: '#FF5238',
		},
		dark: {
			primary: '#7367F0',
			accent: '#0d6efd',
			secondary: '#5c636a',
			success: '#62B726',
			info: '#32B8F2',
			warning: '#FFBF3F',
			error: '#FF5238',
		},
	},
	frest: {
		light: {
			primary: '#5A8DEE',
			secondary: '#6A8DEE',
			accent: '#18BC5A',
			success: '#18BC5A',
			info: '#6BA6F9',
			warning: '#F7EB13',
			error: '#FF4538',
		},
		dark: {
			primary: '#5A8DEE',
			secondary: '#6A8DEE',
			accent: '#18BC5A',
			success: '#18BC5A',
			info: '#6BA6F9',
			warning: '#F7EB13',
			error: '#FF4538',
		},
	},
}

export default themeVariant
