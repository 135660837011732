import { make } from 'vuex-pathify'
import repository from '../../apis/repository'

const state = {
	user: localStorage.user ? JSON.parse(localStorage.getItem('user')) : { },
	token: localStorage.token || null,
}

const defaultGetters = make.getters(state)

const getters = {
	...defaultGetters,
	authenticated() {
		return state.token !== null
	},
}

const mutations = make.mutations(state)

const actions = {
	async login({ commit }, payload) {
		const { data } = await repository.auth.login(payload)
		commit('SET_USER', data.user)
		commit('SET_TOKEN', data.token)
		localStorage.user = JSON.stringify(data.user)
		localStorage.token = data.token

		return data.user
	},
	async logout({ commit }) {
		await repository.auth.logout().finally(() => {
			commit('SET_USER', {})
			commit('SET_TOKEN', null)
			localStorage.removeItem('user')
			localStorage.removeItem('token')
		})
	},
	async getProfile({ commit }) {
		const { data } = await repository.auth.getProfile()
		commit('SET_USER', data.user)
		localStorage.user = JSON.stringify(data.user)

		return data.user
	},
	verifyMail(context, payload) {
		return repository.auth.verifyMail(payload.userId, payload.hash, payload.expires, payload.signature)
	},
	resendVerificationMail() {
		return repository.auth.resendVerificationMail()
	},
	forgotPassword(context, payload) {
		return repository.auth.forgotPassword(payload)
	},
	async resetPassword({ commit }, payload) {
		const { data } = await repository.auth.resetPassword(payload)
		commit('SET_USER', data.user)
		commit('SET_TOKEN', data.token)
		localStorage.user = JSON.stringify(data.user)
		localStorage.token = data.token

		return data.user
	},
	changePassword(context, payload) {
		return repository.auth.changePassword(payload)
	},
	async updateProfile(context, payload) {
		return repository.auth.updateProfile(payload)
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
