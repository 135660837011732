// Custom components
import '@/components/custom'
import '@/plugins/vue-composition-api'
import '@/plugins/vue2-google-maps'
import '@/plugins/vuetify-datetime-picker'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import mixin from './mixin'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.mixin(mixin)
Vue.use(require('vue-moment'))

export default new Vue()

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app')
